import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';
import { RecoilRoot } from 'recoil'

ReactDOM.render(
  <Main>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </Main>,
  document.getElementById('main')
);
