import React, { lazy, Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ProvideAuth, useAuth, } from '../hooks/useAuth';
// import { toast, ToastContainer } from 'react-toastify';
// import { CloseButton, Fade } from '../components/common/Toast';
import { useApi } from '../hooks/useApi';

const DashboardLayout = lazy(() => import('./DashboardLayout'));
const AuthBasicLayout = lazy(() => import('./AuthBasicLayout'));


const Layout = () => {
  return (
    <Suspense fallback={<span />}>
      <ProvideAuth>
        <Router fallback={<span />}>
          <Switch>
            <Route path="/auth" component={AuthBasicLayout} />
            <PrivateRoute path="/" component={DashboardLayout} />
          </Switch>
          {/* <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} /> */}
        </Router>
      </ProvideAuth>
    </Suspense>
  );
};

export default Layout;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { loggedIn } = useAuth()
  const { user } = useAuth()
  const { Fetch } = useApi();

  const [u, setUser] = useState(null)
  useEffect(() => {
      user && Fetch(`/v2/user`, 'GET', { "email": user.email }, true).then((res) => {
          if (res.success) {
              setUser(res.user)
          }
      })

      // eslint-disable-next-line
  }, [user])

  return (
    <Route
      {...rest}
      render={(props) => {
          return loggedIn() && u?.offer_name !== 'gratuit' ? (
            <Component {...props} />
          ) : (
            localStorage.removeItem("refreshToken"),
            localStorage.removeItem("accessToken"),
            <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
          );
        }
      }
    />
  );
};