import React from 'react';
import { useAuth } from './useAuth';

const api = process.env.REACT_APP_API_URL
const apiVersion = 'v2'
export const useApi = () => {

	const { getOAuthToken, getAccessToken, getAuthorization } = useAuth()

	const [loading, setLoading] = React.useState(true)

	const Fetch = async (route, method = 'GET', payload, json = true) => {
		setLoading(true)
		const headers = {
			Authorization: `Bearer ${getOAuthToken()}`,
			jwtToken: getAccessToken()
		}

		if (json) {
			headers['Content-Type'] = 'application/json'
		}

		return await fetch(`${api}${route}`,
			method === "GET" || method === "HEAD" ? {
				headers: { ...headers },
				method: method,
			} : {
				headers: { ...headers },
				method: method,
				body: json ? JSON.stringify(payload) : payload
			})
			.then(async (response) => {
				if (!response.ok) {
					if (response.status === 403) {
						return await getAuthorization().then(() => Fetch(route, method = 'GET', payload, json = true))
					}
				}
				return await response.json()
			})
			.then(async (data) => {
				setLoading(false)
				return await data
			})
			.catch((error) => {
				setLoading(false)
				console.log(error)
			});
	}

	const FetchDownload = async (route, method = 'GET', payload, filename, json = true) => {
		setLoading(true)
		const response = await fetch(`${api}${route}`, {
			headers: {
				Authorization: `Bearer ${getOAuthToken()}`,
				jwtToken: getAccessToken()
			},
			method: method,
			body: json ? JSON.stringify(payload) : payload
		});

		const res = await response.blob()
		const url = window.URL.createObjectURL(new Blob([res]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		setLoading(false)
	}

	const Patch = async (route, method = 'PATCH', payload) => {
		setLoading(true)
		try {
			payload = JSON.parse(payload)
		} catch (err) {
			// ⛔️ Uncaught SyntaxError: JSON.parse: unexpected character at
			// line 1 column 2 of the JSON data
			return 
		}
	  const response = await fetch(`${api}${route}`, {
			method: 'PATCH',
			body: JSON.stringify(payload),
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
				Authorization: `Bearer ${getOAuthToken()}`,
				jwtToken: getAccessToken()
			},
		})
		if (!response.ok) {
			await getAuthorization();
			Patch(route, method, payload)
		}
		setLoading(false)
		return await response.json()
	}

	const Post = async (route, method = 'POST', payload) => {
		setLoading(true)
		try {
			payload = JSON.parse(payload)
		} catch (err) {
			// ⛔️ Uncaught SyntaxError: JSON.parse: unexpected character at
			// line 1 column 2 of the JSON data
			return 
		}
		
	  const response = await fetch(`${api}/${apiVersion}/${route}`, {
			method: 'POST',
			body: JSON.stringify(payload),
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
				Authorization: `Bearer ${getOAuthToken()}`,
				jwtToken: getAccessToken()
			},
		})
		if (!response.ok) {
			await getAuthorization();
			Post(route, method, payload)
		}
		setLoading(false)
		return await response.json()
	}

	const Delete = async (route, method = 'DELETE', payload) => {
		setLoading(true)
		payload = JSON.parse(JSON.stringify(payload))
		
	  const response = await fetch(`${api}/${apiVersion}/${route}`, {
			method: 'DELETE',
			body: JSON.stringify(payload),
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
				Authorization: `Bearer ${getOAuthToken()}`,
				jwtToken: getAccessToken()
			},
		})
		if (!response.ok) {
			await getAuthorization();
			Delete(route, method, payload)
		}
		setLoading(false)
		return await response.json()
	}

	return { Fetch,FetchDownload,Patch,Post,Delete, loading };
};
